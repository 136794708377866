import React from 'react';
import PropTypes from 'prop-types';
import YouTube from 'react-youtube';

class YoutubeBackground extends React.Component {

	constructor(props) {
		super(props)

		const aspectRatio = this.props.aspectRatio.split(':')

		this.state = {
			aspectRatio: aspectRatio[0] / aspectRatio[1],
            videoHeight: 10,
            opacity: 0
		}

		this.defaultPlayerVars = {
			autoplay: 0,
			controls: 1,
			rel: 0,
			showinfo: 0,
			mute: 0,
			modestbranding:1,
			iv_load_policy: 3,
            playsinline:1,
            
            autohide: 1, 
            disablekb: 1, 
            enablejsapi: 1, 
            // For looping video you have to have loop to 1
            // And playlist value equal to your currently playing video
            loop: 0,
		}
	}

	

	componentDidMount() {
		this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this))
        // window.addEventListener("scroll", (event) => {
        //     this.setState({opacity: window.scrollY / 200})
        //     // console.log(this.state.opacity)
        // });
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions.bind(this))
	}

	updateDimensions() {
        if (this.container) {
            const { aspectRatio } = this.state
            const containerWidth = this.container.clientWidth
            const containerHeight = this.container.clientHeight
            const containerAspectRatio = containerWidth / containerHeight

            let videoHeight = containerHeight
            let videoWidth = containerWidth
            let videoY = 52
            let videoX = 0

            if (containerAspectRatio > aspectRatio) {
                videoHeight = (containerWidth / aspectRatio) 
                // videoY = (videoHeight - containerHeight) / -2
            } else {
                // videoWidth = containerHeight * aspectRatio
                videoWidth = containerWidth;
                videoHeight = (containerWidth / aspectRatio) 
                videoX = (videoWidth - containerWidth) / -2
            }

            this.setState({
                videoHeight,
                videoWidth,
                videoY,
                videoX
            });
        }
	}

	onEnd(event) {
		event.target.playVideo();
		this.props.onEnd(event)
	}

	onReady(event) {
		event.target.playVideo();
		this.props.onReady(event)
	}


	render() {
		const { videoHeight, videoWidth, videoX, videoY } = this.state
		const { style, children, className, overlay, playerOptions } = this.props
		const playerProps = (({ videoId,  onPlay, onPause, onError, onStateChange, onPlaybackRateChange, onPlaybackQualityChange}) => 
			({ videoId,  onPlay, onPause, onError, onStateChange, onPlaybackRateChange, onPlaybackQualityChange }))(this.props);
        
		const videoOptions = {
			playerVars: {
				...this.defaultPlayerVars,
				// ...playerOptions
			},
			host: this.props.nocookie ? 'https://www.youtube-nocookie.com' : 'https://www.youtube.com'
		};
        // console.log(videoOptions)

		return (
			<div style={{height: (videoHeight + 52) + 'px', maxHeight: 'calc(100vh)'}} ref={c => this.container = c} className={['container2', className].join(' ')}>
				<div>{children}</div>
				<div
					className='videoContainer'
					style={{
						width: videoWidth + 'px',
						height: videoHeight + 'px',
						top: videoY + 'px',
						left: videoX + 'px'
					}}
				>
					{/* {overlay &&
						<div className='overlay2' style={{ backgroundColor: overlay }}></div>} */}
					<YouTube
						{...playerProps}
						// onReady ={this.onReady.bind(this)}
						// onEnd ={this.onEnd.bind(this)}
						opts={videoOptions}
						className='videoIframe'
						containerClassName='videoInnerContainer'
					></YouTube>
				</div>
			</div>

		);
	}
}

YoutubeBackground.propTypes = {
	videoId: PropTypes.string.isRequired,
	aspectRatio: PropTypes.string,
	overlay: PropTypes.string,
	className: PropTypes.string,
	onReady: PropTypes.func,
	onEnd: PropTypes.func,
	playerOptions: PropTypes.object
};


YoutubeBackground.defaultProps = {
	aspectRatio: '16:9',
	overlay: 'false',
	nocookie: false,
	playerOptions:{},
	onReady: () => { },
	onPlay: () => { },
	onPause: () => { },
	onError: () => { },
	onEnd: () => { },
}


export default YoutubeBackground;